import React from "react";

export default function Columns({num, children}) {

  let colNum;

  console.log("Columns children", children);

  switch(num) {

    case "2":
      colNum = "is-2-desktop";
      break;

    case "3":
      colNum = "is-3-desktop";
      break;

    case "4":
      colNum = "is-4-desktop";
      break;

    default:
      colNum = "is-2-desktop";
  }

  return (
    <div className={`columns ${colNum}`}>
      {React.Children.map(children, (child) => {
        if (child !== "     " ) return (
          <div className="column is-one-third-desktop">
            {child}
          </div>
        )
      })}
    </div>
  )

}