import React from "react";

export default function RelatedVideo({videos}) {

  console.log("Related Video", videos);

  return (
    <div>
    <h3 className="title is-5 mt-6">RELATED VIDEO</h3>
      {videos.map(video => (
        <figure className="image is-16by9 mb-3">
          <iframe className="has-ratio" src={video} width="640" height="360" frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture" allowFullScreen/>
        </figure>
      ))}
    </div>
  )

}