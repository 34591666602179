import React from "react";

export default function FigCaption({children}) {

  return (

    <figcaption style={{fontSize: "0.75em", fontWeight: "normal", textAlign: "center"}}>
      {children}
    </figcaption>

  )

}