import React, {useEffect} from "react";
import { Link } from "gatsby";
import SiteLogo from "../../content/assets/nbw-site-logo.svg";

export default function Header() {

  useEffect(() => {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);


    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {

          console.log("burger clicked");

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-hidden');

        });
      });
    }
  });



  return (

    <nav id="navbar" className="navbar is-fixed-top is-size-7" role="navigation" aria-label="main navigation">
      <div className="container">
      <div className="navbar-brand">
        <a className="navbar-item has-text-white" href="/">
          <SiteLogo className="has-text-white" style={{width: "174px", height: "29px"}}/>
        </a>

        <span role="button" className="navbar-burger" aria-label="menu" aria-expanded="false"
           data-target="navMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </span>

        <div id="navMenu" className="has-background-warning is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-align-content-center has-text-centered is-hidden" style={{position: "absolute", top: "52px", left: "0px", width: "100vw", height: "calc(100vh - 52px"}}>
          <Link to="/#about" className="navbar-item is-size-3">
            ABOUT
          </Link>
          <Link to="/#projects" className="navbar-item is-size-3">
            PROJECTS
          </Link>
          <Link to="/#experience" className="navbar-item is-size-3">
            EXPERIENCE
          </Link>
        </div>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-start">
        </div>


        <div className="navbar-end">
          <Link to="/#about" className="navbar-item">
            ABOUT
          </Link>
          <Link to="/#projects" className="navbar-item">
            PROJECTS
          </Link>

          <Link to="/#experience" className="navbar-item">
            EXPERIENCE
          </Link>
        </div>
      </div>
      </div>
    </nav>

  )
}