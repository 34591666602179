import React from "react";
import { Link, graphql } from "gatsby";
import Bio from "../components/blog/bio";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import RelatedVideo from "../components/blog/relatedVideo";
import Header from "../components/header";
import RelatedPosts from "../components/blog/relatedPosts"
import rehypeReact from "rehype-react";
import PrimaryTitle from "../components/md-components/primaryTitle";
import SecondaryTitle from "../components/md-components/secondaryTitle";
import TertiaryTitle from "../components/md-components/tertiaryTitle";
import Columns from "../components/md-components/columns";
import MarkdownImage from "../components/md-components/mdImage"
import Table from "../components/md-components/table"
import FigCaption from "../components/md-components/figcaption"
import Float from "../components/md-components/float"
import SiteConfig from "../../data/SiteConfig"

const _ = require("lodash");

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { h1: PrimaryTitle,
                h2: SecondaryTitle,
                h3: TertiaryTitle,
                table: Table,
                figcaption: FigCaption,
                'columns': Columns,
                'image-wrapper': MarkdownImage,
                'float': Float
  }
}).Compiler;

const BlogPostTemplate = ({ data }) => {
  console.log("Blog Data", data);
  const post = data.markdownRemark;
  const { previous, next } = data;
  const category = post.frontmatter.category;
  const tags = post.frontmatter.tags;
  const relatedVideo = post.frontmatter.relatedVideo;
  const relatedPosts = post.relatedPosts;
  console.log("Related Video", relatedVideo);
  console.log("Related Blog Posts", relatedPosts);

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Header/>
      <section className="container section">

        <div className="columns is-variable is-1-mobile is-2-tablet is-2-desktop is-2-widescreen is-2-fullhd is-8">
          <div className="column is-two-fifths-tablet is-two-thirds-desktop is-two-thirds-widescreen is-two-thirds-fullhd">
            <article
              className="blog-post"
              itemScope
              itemType="http://schema.org/Article"
            >

              <div className="mb-4">{category && category.sort().map(cat => (
                <Link to={`/category/${_.kebabCase(cat.toString())}`}><span key={`${cat}`} className="tag mr-1">{cat.toUpperCase()}</span></Link>
              ))}</div>

              <div>
                <h1 className="title mb-2 is-size-1" itemProp="headline">{post.frontmatter.title.toUpperCase()}</h1>
                <div className="has-text-grey">{post.frontmatter.description.toUpperCase()}</div>
                <hr style={{borderTop: "6px solid #333333"}}/>
                <div className="mb-5 is-flex is-justify-content-space-between">
                  <div className="has-text-grey is-size-7">{post.frontmatter.date.toUpperCase()}</div>
                  <div className="has-text-grey is-size-7">{post.timeToRead} MINUTE READ</div>
                </div>
              </div>

              {post.frontmatter.featuredImage && (
                <div className="post-content-image mb-5">
                  <Img
                    className="kg-image"
                    fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
                    alt={post.frontmatter.title}
                  />
                </div>
              )}

              <section
                className="is-family-secondary content"
                itemProp="articleBody"
              >
                {renderAst(post.htmlAst)}
              </section>

              <div className="mb-4 mt-6">Tags: {tags && tags.sort().map(tag => (
                <Link to={`/tag/${_.kebabCase(tag.toString())}`}><span key={`${tag}`} className="tag mr-1 has-background-grey-light has-text-white">{tag.toUpperCase()}</span></Link>
              ))}</div>
            </article>
            <hr />

            <nav className="blog-post-nav">
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev" className="has-text-warning-dark">
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="next" className="has-text-warning-dark">
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </nav>
          </div>
          <div className="column is-one-third-tablet is-one-third-desktop is-one-third-widescreen is-one-third-fullhd">
            <Bio accentColor={SiteConfig.themeColors.accent} textColor="#000000"/>
            {relatedVideo && (
              <RelatedVideo videos={relatedVideo}/>
            )}
            {relatedPosts.length > 0 && (
              <RelatedPosts posts={relatedPosts}/>
            )}
          </div>
        </div>


      </section>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1360) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        relatedVideo
        category
        tags
      }
      timeToRead
      relatedPosts(limit: 3) {
        fields {
          slug
        }
        frontmatter {
          title
          date
          description
          category
          tags
          featuredImage {
            childImageSharp {
              fluid(maxWidth: 1360) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      frontmatter {
        title        
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`