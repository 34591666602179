import React from "react";

export default function Float({position, width, children}) {


  if(position === "left") {

    return (
      <div style={{float: position, width: width, marginTop: 10, marginRight: 40, marginBottom: 10}}>
        {children}
      </div>
    )

  } else {

    return (
      <div style={{float: position, width: width, marginTop: 10, marginLeft: 40, marginBottom: 10}}>
        {children}
      </div>
    )

  }
}