import React from "react";

export default function Table({children}) {

  return (

    <table className="table mt-3 mb-3" style={{width: "100%", backgroundColor: "transparent"}}>
      {children}
    </table>

  )

}