import React from "react";
import PostMediaObject from "./postMediaObject";

export default function RelatedPosts({posts}) {

  return (
    <div>
      <h3 className="title is-5 mt-6">RELATED PROJECTS</h3>
      {posts.map((post) => (
          <PostMediaObject post={post}/>
      ))}
    </div>
  )

}