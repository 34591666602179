import React from "react";
import Img from "gatsby-image";
import {Link} from "gatsby";
const _ = require("lodash");

export default function PostMediaObject({post}) {

  console.log("postMediaObject", post);

  return (

    <article className="media">
      <figure className="media-left">
        <p className="image is-64x64">
          <Link to={post.fields.slug}>
          <Img
            fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
          />
          </Link>
        </p>
      </figure>
      <div className="media-content">
        <div className="content">
          <Link to={post.fields.slug}>
            <div className="is-size-5 has-text-grey-darker">{post.frontmatter.title}</div>
          </Link>
          <div className="is-family-secondary is-size-7 has-text-black">{post.frontmatter.description}</div>
          <div>{post.frontmatter.category.map((cat) => (
            <Link to={`/category/${_.kebabCase(cat.toString())}`}><span key={`${cat}`} className="tag mr-1">{cat.toUpperCase()}</span></Link>
          ))}</div>
        </div>
      </div>

    </article>

  )

}